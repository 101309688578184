import { SECRET_KEY_ENCRYTION_COUNTRY_CODE } from "../helper/constants";

export const callToAPI = async (routeUrl, methodData, headerContent) => {
  const res = await fetch(routeUrl, {
    method: methodData,
    headers: headerContent,
  });

  return res;
};

interface EncryptionResult {
  encryptedValue: string;
  iv: string;
}

const encodeBase64 = (str:string) => {
  return window.btoa(str);
};

const padBase64 = (base64) => {
  while (base64.length % 4 !== 0) {
    base64 += "=";
  }
  return base64;
};

export const encryptValue = async (
  value: string,
  salt: string
): Promise<string> => {
  const encoder = new TextEncoder();
  const password = SECRET_KEY_ENCRYTION_COUNTRY_CODE;
  const base64Salt = padBase64(encodeBase64(salt));

  const saltBytes: Uint8Array = Uint8Array.from(window.atob(base64Salt), (c) =>
    c.charCodeAt(0)
  );

  const keyMaterial = await window.crypto.subtle.importKey(
    "raw",
    encoder.encode(password),
    "PBKDF2",
    false,
    ["deriveKey"]
  );

  const key = await window.crypto.subtle.deriveKey(
    {
      name: "PBKDF2",
      salt: saltBytes,
      iterations: 100000,
      hash: "SHA-256",
    },
    keyMaterial,
    { name: "AES-GCM", length: 256 },
    true,
    ["encrypt"]
  );

  const iv: any = window.crypto.getRandomValues(new Uint8Array(12));

  const encrypted: ArrayBuffer = await window.crypto.subtle.encrypt(
    {
      name: "AES-GCM",
      iv: iv,
    },
    key,
    encoder.encode(value)
  );

  const encryptedValue: any = new Uint8Array(encrypted);

  const clocEncrypted = btoa(String.fromCharCode(...encryptedValue));
  const ivEncrypted = btoa(String.fromCharCode(...iv));

  return `${clocEncrypted}||${ivEncrypted}`;
};