import React, { useDebugValue, useEffect, useState } from "react";
import "./TableComponent.scss";
import {
  Card,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  TableFooter,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  FAVOURITE_TABLE,
  TOPHEADERTITLE,
  MODAL_MESSAGE,
  NULL_VALUE,
  PUBLICATION_MEDIA_TYPE_NAME,
  REQUEST_FORM_TEXT,
  DOWNLOAD_TEXT,
  UNASSIGNED_AIRCRAFT,
  ACCESS_STATUS,
  YES_MARK,
  LICENCED,
  REV_TEXT,ADDFAVORITE
} from "../../../helper/constants";
import { Link, useLocation } from "react-router-dom";
import { dummyData } from "../../../data";
import { useDispatch, useSelector } from "react-redux";
import { FacetFilterActions } from "../../../storage/reducers/facetFilterReducer";
import { RECORDS_PER_PAGE_SEARCH_RESULTS } from "../../../helper/constants";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RequestDownloadModal from "../RecentlyDownload/RequestDownloadModal";
import { RequestFormAction } from "../../../storage/reducers/requestFormReducer";
import { GeoCheck } from "../GeoCheck/GeoCheck";

const TableComponent = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const isLibrary = pathname.includes("/subscriptions");
  const isDownloads = pathname.includes("/downloads");
  const isTempRev = pathname.includes("/temprevisions");
  const isReqForm = pathname.includes("/subscription_request");
  const [tableData, setTableData] = useState<any>([]);
  const [loader, setLoader] = useState(false);
  const [handleGeoCheckDownload, setHandleGeoCheckDownload] = useState();
  const [showPopup, setshowPopup] = useState(false);
  const [popupIndex, setPopupIndex] = useState("");
  const [a2a2indicator, setA2A2Indicator] = useState("");
  const [ldm, setLdm] = useState("");
  const {
    tokenValid,
    isLoadingToken
  } = useSelector((state: any) => state.config);
  const approvedStatus = ACCESS_STATUS.APPROVED;
  const { location, resLocationStatus, isLoadingLocation } = useSelector(
    (state: any) => state.location
  );
  const checkStatus = tokenValid?.isSuccess;
  const toolAccess =
    checkStatus === true ? tokenValid?.result?.KEY_TOOL_ACCESS : "";
    const { licdata } = useSelector(
      (state: any) => state.licagreement
    );
  const tableDataTemporaryRevision = {
    ata : props?.tableDetailsData?.ata,
    pub_number : props?.tableDetailsData?.pub_number,
    revision_date : props?.tableDetailsData?.revision_date + 
    
    
    
    +  props?.tableDetailsData?.version_number,
    title : props?.tableDetailsData?.title,
    unassigned_aircraft : props?.userData[0]?.unassigned_aircraft !== "" ? props?.userData[0]?.unassigned_aircraft : t(REQUEST_FORM_TEXT.UNASSIGNED),
    versionNumber : props?.tableDetailsData?.version_number
  };
  const { download_totalRecords, loadingDownload } = useSelector(
    (state: any) => state.config
  );
  const { portalPermissions, loadingPortal, user_details } = useSelector(
    (state: any) => state.config
  );
  const formatDate = (value) => {
    const date = value.split("T");
    return moment(date[0]).format("DD MMM Y");
  };
  const recordsPerPage = RECORDS_PER_PAGE_SEARCH_RESULTS;

  useEffect(() => {
    if (props.loaderFlag !== undefined) {
      setLoader(props.loaderFlag);
    }
    if (props.userData !== undefined) {
      if (props.userData.length > 0) {
        props?.userData?.map((data, index) => {
          
          props.attributes.map((item) => {
            if(item?.type==DOWNLOAD_TEXT)
              {
              props.userData[index][item?.data] = data[item?.data]?data[item?.data]:'';
              }
              else if(item?.type=== ADDFAVORITE)
              { 
                props.userData[index][item?.data] = data[item?.data];
              } 
            else
              { 
                props.userData[index][item?.data] = data[item?.data]?data[item?.data]:NULL_VALUE;
              } 
            if (item?.isTruncate === true) {
              props.userData[index][item?.data] =
                data[item.data]?.length <= 40
                  ? data[item.data]
                  : data[item.data]?.substring(0, 40) + "...";
            }

            if (item.isFormatDate === true) {
              if (data[item.data]?.includes("T")) {
                props.userData[index][item.data] = formatDate(data[item.data]);
              }
            }
            if (item.extraParamData && item.extraParamData.length > 0) {
              for (let i = 0; i < item.extraParamData.length; i++) {
                if (
                  !props.userData[index][item.data]?.includes(
                    item.extraParamPrefix[i]
                  )
                ) {
                  props.userData[index][item.data] =
                    props.userData[index][item.data] +
                    "/" +
                    item.extraParamPrefix[i] +
                    props.userData[index][item.extraParamData[i]];
                }
              }
            }
          });
        });

        const tempArr = [...props.userData];
        setTableData(tempArr);
      } else {
        setTableData([]);
      }
    }
  }, [props.userData]);

  const OnClickOfLableLink = (data) => {
    dispatch(
      FacetFilterActions.getSelectedFilterData({
        facetObjectVal: "",
        facetPayloadVal: {
          queryString: data,
          pageable: {
            pageNo: 1,
            pageSize: recordsPerPage.toString(),
            sortBy: "",
            sortDir: "",
          },
        },
      })
    );
  };
  const onCloseModal = () => {
    setshowPopup(false);
  };
  const RequestDownloadClick = (data, index) => {
   
    setshowPopup(true);
    setPopupIndex(index);

    if (data?.the2A2AIndicator === undefined && props?.a2a2_indicator) {
      setA2A2Indicator(props?.a2a2_indicator);
      setLdm(props?.ldm);
    } else if (data?.the2A2AIndicator !== undefined) {
      setA2A2Indicator(data?.the2A2AIndicator);
      setLdm(data?.limitedDistributionNumber);
    }
  };
  const getFavPubId = (getPubId, favIndicator, title) => {
    props.addRemoveFavoriteHandler(getPubId, favIndicator);

    // toast.success(favIndicator == 'add' ? `${title} Added Successfully` : `${title} removed Successfully`, {
    //   position: "top-right",
    //   autoClose: false,
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    //   theme: "light",
    //   });

    toast.success(
      favIndicator == "add" ? (
        <>
          <h1>Added to Favorites</h1>
          <p>{title}</p>
        </>
      ) : (
        <>
          <h1>Removed from Favorites</h1>
          <p>{title}</p>
        </>
      ),
      {
        position: "top-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        toastId: getPubId,
      }
    );
  };
  const handleGeoCheck = (flag) => {
    setHandleGeoCheckDownload(flag);
  };
  const onCloseModalGeoCheck = () => {
    setshowPopup(false);
  };
  const checkIfGeoCheckTableCompleted = () => 
    {
      return (tokenValid &&
        isLoadingToken === false &&
        isLoadingLocation === false &&
        checkStatus === true &&
        toolAccess != "" &&
        toolAccess == approvedStatus &&
        resLocationStatus === 204 &&
        licdata?.licdata !== 204) &&
        (props?.tempRevData?.["2a2a_indicator"] === YES_MARK || props?.tempRevData?.export_lic_description === LICENCED);
    };
  return (
    <>
      <ToastContainer />
      <div className={`tableComponent ${isDownloads ? "downloadsTble" : ""}`}>
        <div className="recentdownloadTable">
          {" "}
          <div className="recentdownloadTableTitle">
            <h1>{t(props.topHeader)}</h1>
          </div>
          {props.viewAll && (
            <div className="recentdownloadTablebutton">
              {" "}
              <Link to="/downloads">View All</Link>{" "}
            </div>
          )}
        </div>
        {props.isSearch === true && (
          <div className="mylibsearchbar">
            <input
              name="search_query"
              aria-label="Search Query"
              type="text"
              placeholder="Search by Pub Name/No."
              onChange={props.searchHandleChange}
              onKeyDown={props.searchHandleChange}
              value={props.inputValue}
            />
          </div>
        )}
        <Card
          className={`tablecontainer ${isLibrary ? "mylibtablecontainer" : ""}`}
        >
          <Table
            className={`TableWrapper ${isLibrary ? "mylibtable" : ""} ${
              isTempRev ? "TRTable" : ""
            } ${isReqForm ? "request-form-pub-table" : ""}`}
          >
            <TableHead>
              <TableRow>
                {props.attributes.map((item) => {
                  return (
                    <>
                      {item.type == "download" &&
                      props.downloadColumn === false ? (
                        " "
                      ) : (
                        <TableCell>{t(item.label)}</TableCell>
                      )}
                    </>
                  );
                })}
              </TableRow>
            </TableHead>

            <TableBody>
              {props?.userData?.length === 0 && !loader && (
                <TableCell
                  className="no-record-found"
                  colSpan={props.attributes.length}
                >
                  {props.userData.length === 0 ? (
                    <h1>{t(FAVOURITE_TABLE.NO_RECORD_FOUND)}</h1>
                  ) : (
                    <h1></h1>
                  )}
                </TableCell>
              )}
              {tableData?.map((data, index) => {
                return (
                  <TableRow className="datarow" key={index}>
                    {showPopup && popupIndex == index &&
                      checkIfGeoCheckTableCompleted() ? 
                              <GeoCheck handleGeoCheck={handleGeoCheck}
                              onCloseModalGeoCheck={onCloseModalGeoCheck}
                              />
                            :
                            (showPopup && popupIndex == index &&
                            <RequestDownloadModal
                            ldm={ldm}
                            title={data?.pubTitle}
                            pubNumber={
                              props?.purpose === "temp_Revision"
                                ? data?.associatedPubId
                                : data?.pubId
                            }
                            isLocationID = {props?.isLocationID}
                            handleGeoCheckDownload={handleGeoCheckDownload}
                            stampType={props?.purpose === "temp_Revision" ?  props?.stampTypeId : data?.stampTypeId}
                            a2a2={a2a2indicator}
                            show={true}  
                            pubsMedia={data.trs_media}
                            tempRevData={props?.tempRevData}
                            locationId={data?.locationId}
                            tempRevId={data.trId}
                            onCloseModal={onCloseModal}
                            pubId={props?.pubId}
                            udc={props?.udc}
                            msg={
                              props.purpose === "temp_Revision"
                                ? t(MODAL_MESSAGE.TEMP_REVISION_MSG)
                                : t(MODAL_MESSAGE.RECENT_DOWNLD_MSG)
                            }
                          />)
                          }
                    {props.attributes.map((item: any) => {
                      return (
                        <>
                          {item.type == "download" &&
                          props.downloadColumn === false ? (
                            " "
                          ) : (
                            <TableCell>
                              {item.linkTo || item.linkTo == "" ? (
                                <Link
                                  to={item.linkTo}
                                  aria-label={
                                    item.aria_label != ""
                                      ? item.aria_label
                                      : null
                                  }
                                  state={
                                    item.linkParams != ""
                                      ? data[item.linkParams]
                                      : null
                                  }
                                  onClick={() => {
                                    props.purpose == "my_subscription" ||
                                    props.purpose == "downloads"
                                      ? OnClickOfLableLink(
                                          data[item.linkParams]
                                        )
                                      : props.recentDowloadPopup == true
                                      ? RequestDownloadClick(data, index)
                                      : "";
                                  }}
                                  className={
                                    item.classname != "" ? item.classname : null
                                  }
                                  onContextMenu={(e) => e.preventDefault()}
                                >
                                  {data[item.data]}
                                </Link>
                              ) : item.type == "checkbox" ? (
                                <div
                                  className={
                                    data[item.data]
                                      ? "leftcheckbox checked"
                                      : "leftcheckbox"
                                  }
                                >
                                  <input
                                    aria-label="Type Preferences"
                                    type="checkbox"
                                    checked={data[item.data]}
                                  />
                                </div>
                              ) : item.type == "addFavorite" ? (
                                data[item.data] === false ? (
                                  <>
                                    <div
                                      onClick={() =>
                                        getFavPubId(
                                          data.pubId,
                                          "add",
                                          data.pubTitle
                                        )
                                      }
                                      className="addFav"
                                    ></div>
                                  </>
                                ) : (
                                  <>
                                    <div
                                      onClick={() =>
                                        getFavPubId(
                                          data.pubId,
                                          "remove",
                                          data.pubTitle
                                        )
                                      }
                                      className="removeFav"
                                    ></div>{" "}
                                  </>
                                )
                              ) : item.type == "download" &&
                                props.downloadColumn === false ? (
                                <>{""}</>
                              ) :   (
                                <>
                                {data?.trs_media?.find((item) => item?.media_type_name?.toLowerCase() === PUBLICATION_MEDIA_TYPE_NAME) ?
                                  <div
                                    className={
                                      item.classname != "" ? item.classname : null
                                    }
                                    onClick={() => {
                                      props.purpose === "temp_Revision" &&
                                        item.downloadProgress === true &&
                                        RequestDownloadClick(data, index);
                                    }}
                                  >
                                    {props?.tableDetailsData && props?.tableDetailsData?.ata?.length ?
                                    tableDataTemporaryRevision?.[item.data] : data[item.data]}
                                  </div>
                                  :
                                  <div
                                 className={
                                  item?.trNotReq && item.classname != "" ? item.classname : "cls-tempColumn"
                                }
                                  >
                                    {props?.tableDetailsData && props?.tableDetailsData?.ata?.length ?
                                    tableDataTemporaryRevision?.[item.data] : data[item.data]}
                                  </div>}
                               </>
                              )}
                            </TableCell>
                          )}
                        </>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Card>
      </div>
    </>
  );
};
export default TableComponent;