import React, { useEffect } from 'react';

const CallToAECS = () => {
  useEffect(() => {
    const callToAECSApi = async () => {
      try {
        await fetch("/api/techpub/v1/contact-details", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          }
        });
      } catch (error) {
        console.error("Error in fetching data from AECS API", error);
      }
    };

    callToAECSApi();
  }, []);

  return (
  <></>
  );
};

export default CallToAECS;