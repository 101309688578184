import React, { useEffect, useState } from "react";
import "./GetEmailNotification.scss";
import { Button, Modal } from "react-bootstrap";
import {
  ADD_TO_FAVOURITE_LEBELS,
  ERROR_MSG,
  HTTP_STATUS_CODE,
  WATCHLIST_POPUP,
} from "../../../helper/constants";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Loader from "../../common/Loader/Loader";
import ErrorModal from "../../common/ErrorModal/ErrorModal";
import e from "express";
import {
  getServerNameFromGlobalScope,
  utilAnalytics,
} from "../../common/analytics/analyticsUtil";

const GetEmailNotification = (props) => {
  const { t } = useTranslation();
  const [showPopup, setshowPopup] = useState(false);
  const [isEmailAdded, setIsEmailAdded] = useState(false);
  const [errMSg, setErrMsg] = useState("");
  const [loader, setLoader] = useState(false);
  const [isrelatedInd, setrelatedInd] = useState(false);
  const [checked, setChecked] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [errMSG, setErorState] = useState({ falg: false, msg: "" });
  const { pathname } = useLocation();
  const publicationCategoryName =
    props.data.data._source.publication_category_name[0].publication_category;
  const addWatchlist = async () => {
    setDisabled(true);
    //setLoader(true);
    try {
      const response = await fetch("/api/v1/watch-list", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          pubId: props.data.data._source.pub_uuid,
          isManual: true,
          isRelated: isrelatedInd,
          publicationCategory: publicationCategoryName,
        }),
      });
      if (HTTP_STATUS_CODE.STATUS_200 === response.status) {
        setshowPopup(false);
        setLoader(false);
        props.data.data._source.is_watchlist_flag = true;
      } else if (response.status === 401) {
        setErrMsg(t(ERROR_MSG.ERROR_MS));
        setErorState({ falg: true, msg: t(ERROR_MSG.UNATHORIZED) });
      }
    } catch (error) {
      setErorState({ falg: true, msg: t(ERROR_MSG.ERROR_MS) });
    }
  };

  const show = () => {
    setshowPopup(true);
    setDisabled(false);
  };

  const stopEmailpopup = () => {
    setshowPopup(true);
    setIsEmailAdded(true);
    setDisabled(false);
  };

  const removeWatchlist = async () => {
    setDisabled(true);
    try {
      //  setLoader(true);
      const response = await fetch(`/api/v1/watch-list/delete`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          pubId: props.data.data._source.pub_uuid,
        }),
      });
      if (
        HTTP_STATUS_CODE.STATUS_200 === response.status ||
        HTTP_STATUS_CODE.STATUS_204 === response.status
      ) {
        setLoader(false);
        setshowPopup(false);
        setIsEmailAdded(false);
        props.data.data._source.is_watchlist_flag = false;
      } else {
        setErrMsg("Something Went Wrong..");
        setErorState({ falg: true, msg: t(ERROR_MSG.ERROR_MS) });
      }
    } catch (error) {
      setErorState({ falg: true, msg: t(ERROR_MSG.ERROR_MS) });
    }
  };

  const handleClose = () => {
    setshowPopup(false);
  };

  const handleChangeCheckBox = (e) => {
    if (e.target.checked) {
      setChecked(true);
      setrelatedInd(true);
    } else {
      setrelatedInd(false);
      setChecked(false);
    }
  };

  const onChange = () => {
    setErorState({ falg: false, msg: "sucsess" });
  };

  return (
    <>
      {props.data.data._source.is_watchlist_flag == true ? (
        <button
          aria-label="dialog Preferences"
          type="button"
          className="stopemail favlinks"
          onClick={stopEmailpopup}
        >
          {t(WATCHLIST_POPUP.STOP_EMAIL_UPDATE)}
        </button>
      ) : (
        <button
          aria-label="dialog Preferences"
          type="button"
          className="getemail favlinks"
          onClick={() => {
            show();
            const { pub_uuid, title, publication_type_name } =
              props?.data?.data?._source;
            const serverName = getServerNameFromGlobalScope();
            const onlyHostName = window.location.hostname
              .split(".")
              .find((item) => item.includes("aerotechpubs") && item);

            utilAnalytics(
              false,
              {
                eVar5: `${onlyHostName}:${pathname}`,
                pageName: `${onlyHostName}:${pathname}`,
                eVar114: `${pub_uuid} || ${title} || ${publication_type_name}`,
                linkName: "Get Email Updates",
                linkType: "o",
              },
              ""
            );
          }}
        >
          {t(WATCHLIST_POPUP.GET_EMAIL_UPDATE)}
        </button>
      )}

      <Modal
        className={`modal-wrapper favPopup addremovePopup licagreementpopup Popupwrapper ${
          isEmailAdded ? "stopEmailDeletePopup" : "addEmailPopup"
        }`}
        show={showPopup}
      >
        {loader && <Loader />}
        <div className="modalbodycontent">
          <Modal.Header>
            <Modal.Title className="">
              {!isEmailAdded ? (
                <div className="watchlistTitle">{t(WATCHLIST_POPUP.TITLE)}</div>
              ) : (
                <div className="removewatchlistTitle">
                  {t(WATCHLIST_POPUP.REMOVE_WATCHLIST)}
                </div>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {isEmailAdded ? (
              <p className="stopemailbody">
                {t(WATCHLIST_POPUP.REMOVEWATCHLISTBODY)}
              </p>
            ) : (
              <p
                className="addwatchlistMsg"
                dangerouslySetInnerHTML={{
                  __html: t(WATCHLIST_POPUP.WATCHLISTBODY),
                }}
              />
            )}
          </Modal.Body>
          <Modal.Footer>
            <div className="footercontainerlic Popupfooter footercontainer">
              <div className="geocheckbtns">
                {isEmailAdded ? (
                  <>
                    <Button
                      type="button"
                      aria-label="Close Preferences"
                      className="myfavlink managefavlink cancelbtn"
                      variant="secondary"
                      onClick={removeWatchlist}
                    >
                      {t(WATCHLIST_POPUP.REMOVE_WATCHLIST_BTN)}
                    </Button>
                  </>
                ) : (
                  <div className="RelatedPubCheckbox">
                    <div
                      className={
                        checked ? "leftcheckbox checked" : "leftcheckbox"
                      }
                    >
                      <input
                        aria-label="Type Preferences"
                        type="checkbox"
                        onClick={handleChangeCheckBox}
                        disabled={disabled ? true : false}
                      />
                    </div>
                    <label className="isOptional">
                      {" "}
                      {t(WATCHLIST_POPUP.ADD_RELATED_PUB)}
                    </label>
                  </div>
                )}
                {isEmailAdded ? (
                  <Button
                    type="button"
                    aria-label="Close Preferences"
                    className="submitbtn myfavlink active btn btn-primary btn btn-secondary"
                    variant="secondary"
                    onClick={handleClose}
                    disabled={disabled ? true : false}
                  >
                    {t(WATCHLIST_POPUP.BACK_BUTTON)}
                  </Button>
                ) : (
                  <>
                    <Button
                      type="button"
                      aria-label="Close Preferences"
                      className="myfavlink managefavlink cancelbtn"
                      onClick={handleClose}
                      disabled={disabled ? true : false}
                    >
                      {t(ADD_TO_FAVOURITE_LEBELS.CLOSE)}
                    </Button>
                    <Button
                      type="button"
                      aria-label="Close Preferences"
                      className="submitbtn active btn-primary btn savepubbtn btn-secondary"
                      variant="secondary"
                      onClick={addWatchlist}
                    >
                      {t(WATCHLIST_POPUP.SAVE)}
                    </Button>
                  </>
                )}
              </div>
            </div>
          </Modal.Footer>
        </div>
      </Modal>
      <ErrorModal
        errorMsg={errMSG.msg}
        errorFlag={errMSG.falg}
        onClose={onChange}
      />
    </>
  );
};

export default GetEmailNotification;
