import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { COLLECTION_ATTRIBUTES, LABELKEYS_SEARCH_RESULTS, TR_UPDATE_ATTRIBUTES, VIEW_COLLECTION } from "../../../helper/constants";
import SearchResults from "../../pages/SearchResults/SearchResults";
import Feature from "../FeatureFlags/Feature/Feature";

const CollectionTabData = (props) => {
  const { t } = useTranslation();
  const COLLECTION_TR_UPDATE_ATTRIBUTE = props.type == 'TR' ? TR_UPDATE_ATTRIBUTES : COLLECTION_ATTRIBUTES;
  return (
    <>
      {props.dataLength > 0 && props.error.length === 0 ? (<Feature name="Search Results">
        <SearchResults
          dataSearch={props.searchData}
          Watchlistflag={true}
          searchval={props.seacrhval}
          load={false}
          udc={props?.udc}
          showMore={props.type == 'TR' ? false : true}
          attributes={COLLECTION_TR_UPDATE_ATTRIBUTE}
          parentSubscriptionFlag={props.subscriptionFlag}
          className='ViewcollectionContainer'
          type = {props.type ? props.type : ''}
          
        />
      </Feature>
      ) :
      props.error.length != 0 && props.updates !== true ? (
        <div className="err-msg">
          <h1 className="erro-msg NodataAvailable">{props.error}</h1>
        </div>
      ) :
       props.updates !== true  ?
          (<h1 className="erro-msg NodataAvailable">
            {t(LABELKEYS_SEARCH_RESULTS.NO_DATA_AVAILABLE)}
          </h1>) :
          (<h1 className="erro-msg NodataAvailable">
            {t(VIEW_COLLECTION.NO_UPDATES_MSG)}
          </h1>)
      }
      {}
    </>
  );
};

export default CollectionTabData;
