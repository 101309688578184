import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  ADD_TO_FAVOURITE_LEBELS,
  ERROR_MSG,
  WATCHLIST_POPUP,
} from "../../../helper/constants";
import "./ErrorModal.scss";
const ErrorModal = ({ errorMsg, errorFlag, onClose }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    onClose();
  };

  useEffect(() => {
    if (errorFlag) {
      setShow(true);
    }
  }, [errorFlag]);

  return (
    <Modal
      className="modal-wrapper favPopup addremovePopup licagreementpopup Popupwrapper errorPopup"
      aria-label="lic poppup"
      show={show}
      backdrop="static"
      id="lic_popup"
    >
      <div className="modalbodycontent modalforwatchlist">
        <Modal.Header>
          <Modal.Title className="">
            <div className="removewatchlistTitle">{t(ERROR_MSG.TITILE)}</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="stopemailbody">
            {errorMsg === "Invalid Search Keyword"
              ? errorMsg
              : "We are unable to process your request at this moment. Please try again after sometime."}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <div className="footercontainerlic Popupfooter footercontainer">
            <div className="geocheckbtns">
              <div className="rightbtn">
                <Button
                  type="button"
                  aria-label="Close Preferences"
                  className="submitbtn active btn btn-primary"
                  variant="secondary"
                  onClick={handleClose}
                >
                  {t(ADD_TO_FAVOURITE_LEBELS.CLOSE)}
                </Button>
              </div>
            </div>
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
};
export default ErrorModal;
