import { t } from "i18next";
import {
  DOWNLOAD_PUBLICATION_SERVER_ERRORS_MESSAGES,
  REQUEST_ERRORMODAL,
  DOWNLOAD_PDF_ERROR,
  REQUEST_DOWNLOAD_MESSAGE,
} from "../../../helper/constants";

export const getEncryptCallErrorMessages = (error: any) => {
  const errorDescription = error?.error?.error?.errorDescription || null;
  const errorCode = error?.error?.error?.errorCode || null;

  const customError = {
    typeError: "network error",
    status: errorCode || 500,
    title:"",
    message: t(REQUEST_ERRORMODAL.ERROR_MSG),
  };

  if (errorDescription) {
    try {
      const errorDescriptionMatch = errorDescription.match(/"({.*})"/);
      if (errorDescriptionMatch && errorDescriptionMatch[1]) {
        const errorDescriptionObj = JSON.parse(errorDescriptionMatch[1]);

        const errorStatus =
          (errorDescriptionObj && errorDescriptionObj?.status) || null;

        if (errorStatus) {
          customError.status = errorStatus;
        }
        const errorMessages: string[] = [];

        for (const key in errorDescriptionObj.errors) {
          if (errorDescriptionObj.errors.hasOwnProperty(key)) {
            errorMessages.push(...errorDescriptionObj.errors[key]);
          }
        }

        if (errorMessages.length > 0 && errorStatus) {
          // customError.message = errorMessages.join("\n");
          // customError.message = t(DOWNLOAD_PDF_ERROR.CUSTOM.MESSAGE);
          customError.title = t(REQUEST_DOWNLOAD_MESSAGE.HEADLINE);
          customError.message = t(REQUEST_DOWNLOAD_MESSAGE.NON_LICENCED_VIEW);
        } else {
          // customError.message = t(DOWNLOAD_PDF_ERROR.DEFAULT.MESSAGE);
          customError.message = t(REQUEST_ERRORMODAL.ERROR_MSG);
        }
      } else if(typeof errorDescription == 'string' && !errorDescription.includes("500 Internal Server Error")){
        customError.title = t(REQUEST_DOWNLOAD_MESSAGE.HEADLINE);
        customError.message = t(REQUEST_DOWNLOAD_MESSAGE.NON_LICENCED_VIEW);
      } 
    } catch (e) {
      customError.message =
        t(DOWNLOAD_PUBLICATION_SERVER_ERRORS_MESSAGES[errorCode]) ||
        t(REQUEST_ERRORMODAL.ERROR_MSG);
    }
  }

  return customError;
};