import React, { useEffect, useState } from "react";
import "./SearchResults.scss";
import DOMPurify from "dompurify";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  SEARCH_RESULTS_ATTRIBUTES,
  FILTER_FACETS,
  LABELKEYS_SEARCH_RESULTS,
  DEFAULT_SHOW_MORE_LENGTH,
  RECORDS_PER_PAGE_SEARCH_RESULTS,
  PATHNAMEURL,
} from "../../../helper/constants";
import FilterFacets from "./FilterFacets/FilterFacets";
import MetaData from "../../common/MetaData/MetaData";
import { Pagination } from "@mui/material";
import { useLocation } from "react-router-dom";
import { ScrollPageActions } from "../../../storage/reducers/scrollPageReducer";
import SearchResultHeader from "./SearchResultHeader/SearchResultHeader";

const SearchResults = (props) => {
  const [searchData, setSearchData] = useState<any>([]);
  const [filterFacetData, setFilterFacetData] = useState<any>([]);
  const [pageSelected, setPageSelected] = useState(1);
  const [totalPage, setTotalPage] = useState();
  const [totalRecords, setTotalRecords] = useState(0);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const recordsPerPage: any = RECORDS_PER_PAGE_SEARCH_RESULTS;
  const [showPaginator, setShowPaginator] = useState<any>(false);

  const facetObjStateVal = useSelector((state: any) => {
    return state.filteredFacets.facetObj;
  });

  const selectedFactesStateVal = useSelector((state: any) => {
    return state.filteredFacets.selectedFactes;
  });

  const attributes = props.attributes
    ? props.attributes
    : SEARCH_RESULTS_ATTRIBUTES;
  const showMoreVal = props.showMore ? props.showMore : false;
  const sortbyrelevance = [
    { label: "By Date", value: "by_date" },
    { label: "By Time", value: "by_time" },
    { label: "Accending", value: "accending" },
    { label: "Decending", value: "decending" },
    { label: "By Name", value: "by_name" },
  ];

  const { t } = useTranslation();

  const configData = useSelector(
    (state: any) => state.config?.configData || {}
  );

  const [data, setData] = useState("");

  const getFilteredSearchData = (data) => {
    setSearchData(data);
    setFilterFacetData(data?.aggregations);
  };

  const countTotalPages = (records) => {
    const numberOfPages: any = Math.ceil(records / recordsPerPage);
    setTotalPage(numberOfPages);

    return numberOfPages;
  };

  const searchBlankData = () => {
    if (
      searchData?.hits?.total !== undefined &&
      props?.searchval?.trim() == "" &&
      props.isStartOver === false
    ) {
      props?.setShowBrowseBy(true);
    }
  };

  const showHidePaginator = (pages) => {
    pages > 1 ? setShowPaginator(true) : setShowPaginator(false);
  };

  useEffect(() => {
    searchBlankData();
    setSearchData(props?.dataSearch);
    setFilterFacetData(props?.dataSearch?.aggregations);
    setPageSelected(props.currentPage);
    setTotalRecords(props?.dataSearch?.hits?.total.value);
    const numberOfPages: any = countTotalPages(
      props?.dataSearch?.hits?.total.value
    );
    const pagiantorFlag = props.paginator ? props.paginator : false;
    if (pagiantorFlag) {
      showHidePaginator(numberOfPages);
    }
  }, [props.load, props?.dataSearch, props?.showBrowseBy]);

  const onClickStartOverBtn = () => {
    setTotalRecords(0);
    props.setIsStartOver(() => {
      return true;
    });
  };
  const resetToDefaultPage = () => {
    setPageSelected(1);
  };
  const onFilterFacetPaginate = (totalRecords) => {
    const numberOfPages: any = countTotalPages(totalRecords);
    numberOfPages > 1 ? setShowPaginator(true) : setShowPaginator(false);
    setPageSelected(1);
    setTotalRecords(totalRecords);
  };

  const handlePageChange = (event, page) => {
    dispatch(
      ScrollPageActions.getScrollPagePosition({
        pageNo: page,
      })
    );
    window.scrollTo(0, window.innerHeight / 2);
    setPageSelected(page);
    if (pathname !== PATHNAMEURL) {
      props.onPageSelected(page);
    }
  };
  return (
    <div
      className={`WrapperContainer ${
        props.className !== undefined ? props.className : ""
      }`}
    >
      {!props?.load &&
        props?.searchval?.trim() != "" &&
        props?.facetFilter == true && (
          <SearchResultHeader
            apiPayload={selectedFactesStateVal}
            recordsCounter={totalRecords}
          />
        )}

      {!props?.Watchlistflag && (
        <div className="header-serach-result">
          <span>{t(LABELKEYS_SEARCH_RESULTS.ITEMS)}</span>

          <Select
            aria-label="Search Sorting"
            className="sortby-relevance"
            options={sortbyrelevance}
            onChange={(opt) => console.log(opt?.label, opt?.value)}
            placeholder="Sort by Relevance"
          />
        </div>
      )}

      {searchData?.hits?.total?.value === 0 && props?.load === false ? (
        props?.searchval.trim() == "" && props?.isStartOver === false ? (
          <h1 className="erro-msg">
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  t(LABELKEYS_SEARCH_RESULTS.EMPTY_SEARCH)
                ),
              }}
            />
          </h1>
        ) : facetObjStateVal &&
          Object.keys(facetObjStateVal).includes("range") ? (
          <h1 className="FilterFacet erro-msg">
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  t(LABELKEYS_SEARCH_RESULTS.NO_DATE_DATE_RANGE)
                ),
              }}
            />
          </h1>
        ) : (
          facetObjStateVal !== "" &&
          props?.dataSearch?.hits?.total?.value === 0 &&
          props?.load === false &&
          props?.searchval !== " " && (
            <h1 className="erro-msg">
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    t(LABELKEYS_SEARCH_RESULTS.NO_SEARCH_DATA_AVAILABLE)
                  ),
                }}
              />
            </h1>
          )
        )
      ) : searchData?.hits?.total !== undefined &&
        props.searchval?.trim() == "" &&
        props.isStartOver === false ? (
        <h1 className="erro-msg">
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                t(LABELKEYS_SEARCH_RESULTS.EMPTY_SEARCH)
              ),
            }}
          />
        </h1>
      ) : (
        ""
      )}

      <div className="main-panel-class">
        {props?.dataSearch?.hits?.total?.value === undefined ||
        props?.Watchlistflag ? (
          ""
        ) : (
          <>
            <div className="Leftpanel">
              {props.facetFilter == true &&
                props?.dataSearch?.hits?.total?.value > 0 &&
                props.load === false &&
                props?.searchval?.trim() != "" && (
                  <>
                    <FilterFacets
                      filterFacetAttributes={FILTER_FACETS}
                      filterFacetValue={filterFacetData && filterFacetData}
                      searchVal={props.searchval}
                      getFilteredSearchData={getFilteredSearchData}
                      onClickStartOverBtn={onClickStartOverBtn}
                      currentPage={pageSelected}
                      onFilterFacetPaginate={onFilterFacetPaginate}
                      resetToDefaultPage={resetToDefaultPage}
                    ></FilterFacets>
                  </>
                )}
            </div>
          </>
        )}
        <div className="search-class searchRecords">
          {props.load === false && props?.searchval?.trim() != "" && (
            <MetaData
              attributes={attributes}
              searchData={searchData}
              showMore={showMoreVal}
              showMoreAttrLength={DEFAULT_SHOW_MORE_LENGTH}
              seacrchval={props.searchval}
              type={props.type}
              parentSubscriptionFlag={props.parentSubscriptionFlag}
              fileStreamerParams={
                props.fileStreamerParams ? props.fileStreamerParams : ""
              }
              udc={props?.udc}
            ></MetaData>
          )}
        </div>
        <div className="searchResultsPagination">
          {props.load === false &&
            props?.searchval?.trim() != "" &&
            props?.dataSearch?.hits?.total?.value > 0 &&
            showPaginator && (
              <Pagination
                className="myfavpagination"
                count={totalPage}
                page={pageSelected}
                onChange={handlePageChange}
                hidePrevButton={false}
                hideNextButton={false}
                showFirstButton={true}
                showLastButton={true}
                boundaryCount={0}
                siblingCount={4}
                shape={"rounded"}
                color={"primary"}
              />
            )}
        </div>
      </div>
    </div>
  );
};
export default SearchResults;
